<template>
  <div class="contactUs">
    <img
      src="../assets/lianxi.png"
      style="position: absolute; top: 100px; left: 745px"
    />
    <div class="aboutList">
      <div class="aboutItem">
        <img src="../assets/21.png" style="width: 130px; height: 112px" />
        <div class="title">
          <div class="item_title">公司名称</div>
          <div class="item_title2">卓邦软件(西安)股份有限公司</div>
        </div>
      </div>
      <div class="aboutItem">
        <img src="../assets/21-1.png" style="width: 130px; height: 112px" />
        <div class="title">
          <div class="item_title">邮箱</div>
          <div class="item_title2">hanzhe@goboosoft.com</div>
        </div>
      </div>
      <div class="aboutItem">
        <img src="../assets/21-2.png" style="width: 130px; height: 112px" />
        <div class="title">
          <div class="item_title">地址</div>
          <div class="item_title2">
            陕西省西安市雁塔区绿地领海大厦B座（南门）2006
          </div>
        </div>
      </div>
      <div class="aboutItem">
        <img src="../assets/21-3.png" style="width: 130px; height: 112px" />
        <div class="title">
          <div class="item_title">联系人</div>
          <div class="item_title2">韩先生</div>
        </div>
      </div>
      <div class="aboutItem">
        <img src="../assets/21-4.png" style="width: 130px; height: 112px" />
        <div class="title">
          <div class="item_title">联系电话</div>
          <div class="item_title2">029-84505016/13572860975</div>
        </div>
      </div>
    </div>
    <img
      src="../assets/ditu.png"
      style="
        width: 1200px;
        height: 450px;
        position: absolute;
        left: 360px;
        top: 754px;
      "
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "2222",
    };
  },
};
</script>

<style>
.contactUs {
  position: relative;
  width: 100%;
  height: 1284px;
  background-color: #ffffff;
}
.aboutList {
  position: absolute;
  left: 381px;
  top: 280px;
  width: 1158px;
  height: 450px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.aboutItem {
  width: 579px;
  height: 112px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.title {
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 19px;
}
.item_title {
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.item_title2 {
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
}
</style>